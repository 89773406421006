import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { ContextService } from '@core/services/context.service';

@Injectable({providedIn: 'root'})
export class HeadersService {

    constructor(private contextService: ContextService) {
    }

    public getHeadersWithHash(): HttpHeaders {
        const encryptedContext = this.contextService.getContext().encryptedContext;
        return new HttpHeaders().set('Hash', encryptedContext);
    }

    public getHeadersWithCustomHash(encryptedContext: string ): HttpHeaders {
        return new HttpHeaders().set('Hash', encryptedContext);
    }

}
