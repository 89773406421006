import { Injectable } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Context } from '@core/model';
import { PbkmVariant } from '@core/enum';
import { PolicyService } from '@core/services';

import { DecryptContextHttpService } from './decrypt-context-http.service';
import { PolicyInfoHttpService } from './policy-info-http.service';
import { PolicyInfo } from './model/policy-info';
import { DecryptedContext } from './model/decrypted-context';

@Injectable({providedIn: 'root'})
export class ContextUrlLoaderService {

    constructor(private decryptContextHttpService: DecryptContextHttpService,
                private policyInfoHttpService: PolicyInfoHttpService,
                private policyService: PolicyService) {
    }

    public loadContext(encryptedContext: string): Observable<Context> {
        return this.decryptContext(encryptedContext)
            .pipe(
                switchMap(decryptedContext =>
                    zip(
                        this.policyInfoHttpService.fetchPolicyInfo(decryptedContext, encryptedContext),
                        of({decryptedContext, encryptedContext})
                    )
                ),
                switchMap(([policyInfo, ctx]) =>
                    zip(
                        this.policyService.findPbkmVariant(
                            policyInfo.agentNo || ctx.decryptedContext.agentNo,
                            ctx.decryptedContext.proposalRef,
                            policyInfo.groupPolicyRef,
                            policyInfo.productType
                        ),
                        of(policyInfo),
                        of(ctx)
                    )
                ),
                map(([pbkmVariant, policyInfo, decryptedAndEncryptedContext]:
                         [PbkmVariant, PolicyInfo, { decryptedContext: DecryptedContext, encryptedContext: string }]) =>
                    this.convertToContext(pbkmVariant, decryptedAndEncryptedContext, policyInfo)
                ));
    }

    private decryptContext(hash: string): Observable<DecryptedContext> {
        return this.decryptContextHttpService.decrypt(hash);
    }

    private convertToContext(pbkmVariant: PbkmVariant,
                             decryptedAndEncryptedContext: { decryptedContext: DecryptedContext, encryptedContext: string },
                             policyInfo: PolicyInfo): Context {
        return {
            proposalRef: decryptedAndEncryptedContext.decryptedContext.proposalRef || policyInfo.groupProposalRef,
            agentNo: policyInfo.agentNo || decryptedAndEncryptedContext.decryptedContext.agentNo,
            linkGenerationAgentNo: decryptedAndEncryptedContext.decryptedContext.agentNo,
            employee: decryptedAndEncryptedContext.decryptedContext.employeeData,
            isConditionsChange: decryptedAndEncryptedContext.decryptedContext.isConditionsChange,
            productCode: policyInfo.productType,
            policyStartDate: policyInfo.startDate,
            policyRef: policyInfo.groupPolicyRef,
            onlyEmployeePolicy: policyInfo.onlyEmployeePolicy,
            encryptedContext: decryptedAndEncryptedContext.encryptedContext,
            certificateRef: decryptedAndEncryptedContext.decryptedContext.insuredProposalRef
                || decryptedAndEncryptedContext.decryptedContext.certificateNo,
            pbkmVariant,
            notRequiredAMLData: policyInfo.notRequiredAMLData,
            insurerName: policyInfo.insurerName
        };
    }
}
